import {
	ChangeDetectionStrategy,
	Component,
	inject,
	input,
} from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { IonIcon, IonButtons } from '@ionic/angular/standalone';
import { closeOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { ModalService } from '@wndr/common/core/services/modal.service';

/** CSS class to disable ion-modal focus trap. */
export const ION_DISABLE_FOCUS_TRAP_CLASS = 'ion-disable-focus-trap';

/** Modal wrapper component. */
@Component({
	selector: 'wndrc-modal-wrapper',
	standalone: true,
	imports: [CommonModule, IonButtons, IonIcon, NgClass],
	templateUrl: './modal-wrapper.component.html',
	styleUrl: './modal-wrapper.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalWrapperComponent {

	private readonly modalService = inject(ModalService);

	/** Modal's title. */
	public readonly title = input.required<string>();

	/** Whether modal has paddings or it's child responsibility. */
	public readonly hasPaddings = input(true);

	/** Whether "close" button is shown. */
	public readonly showCloseButton = input(true);

	/**
	 * Whether modal is scrollable by default or it's child responsibility.
	 * For example, infinite scroll inside.
	 */
	public readonly scrollable = input(true);

	public constructor() {
		addIcons({ closeOutline });
	}

	/** On close button click handler. */
	protected onCloseButtonClick(): void {
		this.modalService.close();
	}
}
