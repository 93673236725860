<header class="header">
	<div class="header__secondary-button-container">
		<ng-content select="[slot=secondary-button]" />
	</div>
	<h1 class="header__title">{{ title() }}</h1>
	@if (showCloseButton()) {
		<button
			class="header__button header__button_close"
			type="button"
			(click)="onCloseButtonClick()"
		>
			<ion-icon
				class="header__button-icon"
				icon="close-outline"
			/>
		</button>
	}
</header>

<div class="container">
	<section
		class="content"
		[ngClass]="{
			content_scrollable: scrollable(),
			paddings: hasPaddings()
		}"
	>
		<ng-content />
	</section>
	<footer
		class="footer"
		[class.paddings]="hasPaddings()"
	>
		<ng-content select="[slot=bottom]" />
	</footer>
</div>
